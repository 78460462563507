import { Link, graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { slugify } from "transliteration";
import { Constants } from "../@types/Constants";
import IParagraph from "../@types/IParagraph";
import ParagraphLayout from "../components/common/ParagraphLayout/ParagraphLayout";
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";
import { List } from "../pageHelpers/Locations/LocationsHelpers";
import { getAddressStateName } from "../services/address";
import { convertArrayToObject } from "../services/helpers";
import { getParagraph } from "../utils/paragraphHelpers";

const Locations: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      page: nodePage(path: { alias: { eq: "/sonesta-locations" } }) {
        id
        title
        field_meta_description
        relationships {
          paragraphs: field_sections {
            type: __typename
            ...ParagraphPageBanner
            ...ParagraphPageIntro
            ...ParagraphPageTeaser
            ...ParagraphFeaturedProperties
            ...ParagraphTeaserList
            ...ParagraphFrequentlyAskedQuestions
          }
        }
      }
      allNodeState(sort: { order: ASC, fields: title }) {
        countries: group(field: field_address___country_code) {
          country: fieldValue
          states: edges {
            node {
              title
              path {
                alias
              }
            }
          }
        }
      }
      allCityStates: allLocationCity {
        countries: group(field: field_address___country_code) {
          country: fieldValue
          states: group(field: field_address___administrative_area) {
            title: fieldValue
            path: distinct(field: fields___path___parent_alias)
          }
          cities: nodes {
            name
            path {
              alias
            }
            field_hotels_with_in_25_miles
            relationships {
              field_sites {
                machine_name
              }
            }
            fields {
              path {
                parent_alias
                alias
              }
            }
          }
        }
      }
    }
  `);
  type ICountry = {
    [s: string]: {
      states:
        | Array<{
            node: {
              title: string;
              path: {
                alias: string;
              };
            };
            title: string;
            path: Array<string>;
          }>
        | [];
      cities: Array<{
        name: string;
        path: {
          alias: string;
        };
        field_hotels_with_in_25_miles: Array<string>;
        relationships: {
          field_sites: Array<{
            machine_name: string;
          }>;
        };
        fields: {
          path: {
            parent_alias: string;
            alias: string;
          };
        };
      }>;
    };
  };

  let cityCountries: ICountry = {};
  if (data.allCityStates.countries.length) {
    cityCountries = convertArrayToObject(
      data.allCityStates.countries,
      "country"
    );
  }

  let countries: ICountry = {};
  if (data.allNodeState.countries.length) {
    countries = convertArrayToObject(data.allNodeState.countries, "country");
    if (countries) {
      for (const [code, country] of Object.entries(countries)) {
        country.states.forEach(
          (
            state: { node: { title: any; path: { alias: any } } },
            idx: number
          ) => {
            if (!countries[code].states[idx].title) {
              countries[code].states[idx] = {
                ...countries[code].states[idx],
                title: state.node.title,
                path: [state.node.path.alias],
              };
            }

            const thisCountryCities =
              cityCountries && cityCountries[code].cities;
            if (thisCountryCities) {
              countries[code].cities = thisCountryCities;
            }
          }
        );
      }
    }
  }

  const allCountries: ICountry = { ...cityCountries, ...countries };

  //filter out redlion cities and states not having any city
  for (const [code] of Object.entries(allCountries)) {
    const thisCountryCities = cityCountries && cityCountries[code].cities;
    const thisCountryStates = cityCountries && cityCountries[code].states;
    allCountries[code].states = thisCountryStates.filter((state) => {
      const thisStateCities = thisCountryCities.filter((city) => {
        const field_sites = city.relationships?.field_sites || [];
        const isSonestaCity =
          field_sites &&
          (!field_sites.length ||
            field_sites.some(
              (site: { machine_name: string }) =>
                site.machine_name === "sonesta"
            ));
        return city.fields.path.parent_alias == state.path[0] && isSonestaCity;
      });
      return thisStateCities && thisStateCities.length > 0;
    });
  }
  const countriesNames: { [Key: string]: string } = {
    US: "United States",
    CA: "Canada",
    CL: "Chile",
    CO: "Colombia",
    EC: "Ecuador",
    EG: "Egypt",
    MX: "Mexico",
    PE: "Peru",
    SX: "Sint Maarten",
  };

  const sortOrder = Object.keys(countriesNames);

  const orderedCountryCodes = Object.keys(allCountries).sort((a, b) => {
    return sortOrder.indexOf(a) - sortOrder.indexOf(b);
  });

  const _paragraphs = data.page.relationships.paragraphs.map(getParagraph);
  const _pageBanner = _paragraphs.filter((p: IParagraph) => {
    return p
      ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_BANNER
      : false;
  });
  const _pageIntro = _paragraphs.filter((p: IParagraph) => {
    return p ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_INTRO : false;
  });
  const _pageComponents = _paragraphs.filter((p: IParagraph) => {
    return p
      ? p.props.paragraphtype !== Constants.PARAGRAPH_PAGE_BANNER &&
          p.props.paragraphtype !== Constants.PARAGRAPH_PAGE_INTRO
      : false;
  });
  return (
    <Layout>
      <SEO
        title={data?.page?.title || "Our Locations"}
        description={data?.page?.field_meta_description || ""}
        includeLeaflet={true}
      />
      {_pageBanner}
      {_pageIntro}
      <Container className="mt-4 mx-lg-auto" fluid="sm">
        <Row>
          <Col>
            <h1 className="text-center">Our Locations</h1>
          </Col>
        </Row>
        {orderedCountryCodes.map((code) => {
          const states = allCountries[code].states.sort((a, b) => {
            if (a.path < b.path) {
              return -1;
            }
            if (a.path > b.path) {
              return 1;
            }
            return 0;
          });
          const cities = allCountries[code].cities;
          const cityWithHotels: Array<any> = [];
          cities.forEach((city) => {
            if (
              city.field_hotels_with_in_25_miles.length > 0 &&
              city.field_hotels_with_in_25_miles.includes("31")
            ) {
              cityWithHotels.push(city);
            }
          });
          if (cityWithHotels.length > 0) {
            return states?.length || cityWithHotels?.length ? (
              <Row key={`country-${code}`}>
                <Col>
                  <h2 id={`${slugify(countriesNames[code])}-hotels`}>
                    {countriesNames[code]} Hotels
                  </h2>
                  <List columncount={4}>
                    {states.length
                      ? states.map((state: any) => {
                          const stateFullName = getAddressStateName(
                            code,
                            state.title
                          );
                          const title = stateFullName
                            ? stateFullName
                            : state.title;
                          return (
                            <li key={title}>
                              <Link
                                id={`location-${slugify(title)}`}
                                to={state.path[0]}
                              >
                                {title}
                              </Link>
                            </li>
                          );
                        })
                      : cityWithHotels.length
                      ? cityWithHotels.map((city: any) => {
                          return (
                            <li key={city.name}>
                              <Link to={city.fields.path.alias}>
                                {city.name}
                              </Link>
                            </li>
                          );
                        })
                      : null}
                  </List>
                </Col>
              </Row>
            ) : null;
          }
        })}
      </Container>
      <ParagraphLayout {..._pageComponents} />
    </Layout>
  );
};

export default Locations;
